<template>
   <div>
     <CCard class="col-md-12">
      <CRow class="p-3">
       <h4 class="color-light ml-2 page_title"><span class="ul_text">DO</span>MAIN SECURITY</h4>
      </CRow>
      <CCardBody class="col-md-12">
                <CRow>
               <CTextarea
                class="col-md-8"
                label="Domain Blacklist"
                size="sm"
                v-model="domain.domain_blacklist"
                required
                placeholder="Domain Blacklist"
                :state="validation"
              ></CTextarea>
              <p class="col-md-10" style="font-size: 12px;">Customer Domain Blacklist: All visits from these domains will be blocked independent of your security settings. Please enter a comma separated list of domains.</p>
              <!-- </CCol> -->
              <!-- <b-form-invalid-feedback
                style="margin-left:160px;"
                :state="validation"
              >Please add a name for the traffic source which will help you identify it later</b-form-invalid-feedback> -->
            </CRow>
            <CRow class="mt-3 col-md-12">
              <CButton type="submit" v-on:click="onSubmit" color="success">Save</CButton>
        </CRow>
      </CCardBody></CCard>
   </div> 
</template>

<script>
import Swal from 'sweetalert2/dist/sweetalert2.js'
import 'sweetalert2/src/sweetalert2.scss'
import SettingService from './../../services/settingControlService'
export default {
    data(){
        return{
            domain:{domain_blacklist:''}
        }
    },
    async mounted() {
      if(window.localStorage.getItem('user') && window.localStorage.getItem('status') != 'true'){
          this.$router.push('/')
      }
      else{
      try {
        let response = await SettingService.domainget()
      
      if(response.message){
      this.domain.domain_blacklist = response.message.domainbklist.toString() }
    } catch (e) {
      console.log('connection error')
      // this.errorNotify("Error", "connection error", "danger");
    }
    }
    },
    computed: {
    validation() {
    //   if (this.mainObject.offername == "") {
    //     return false;
    //   }
    }
  },
    methods: {
      addTag(newTag) {
        const tag = {
          name: newTag,
          _id: newTag.substring(0, 2) + Math.floor(Math.random() * 10000000)
        };
        this.options.push(tag);
        this.value.push(tag);
      },
    async onSubmit(){
      try {
      let domain_data = {
          domainbklist: this.domain.domain_blacklist
      }
      let response = await SettingService.domain(domain_data)
       if(response.result){
          Swal.fire({title: 'Success!',text: response.message,icon: 'success',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        }
        else{
          Swal.fire({title: 'Error!',text: response.message,icon: 'error',confirmButtonText: 'Close',position: 'top-end',showConfirmButton: false,timer: 1000})
        }
      } catch (e) {
          // Swal.fire({title: 'Error!',text: "connection error",icon: 'error',confirmButtonText: 'Ok',position: 'top-end',showConfirmButton: false,timer: 1000})
        // this.errorNotify("Error", "connection error", "danger");
      }
    },
    }
}
</script>

<style scoped>
label {
  text-align: left;
  font-weight: 400;
  color: black;
  font-size: 12px;
}
</style>
